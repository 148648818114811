<template>
  <v-app>
    <v-main id="hero">
      <v-row justify="start" align="center" class="h-100">
        <v-col cols="10" md="4" offset-md="1">
          <v-card class="py-5 px-5">
            <v-container>
              <p class="text-center h3 font-weight-bold">Login</p>
              <v-alert v-if="showError" type="error">
                <div class="title">Authentication Error</div>
                <div>
                  Failed to login with your credentials, please ensure your
                  email and password are correct
                </div>
              </v-alert>
              <v-form @submit.prevent="handleLogin" ref="form">
                <small>Email</small>
                <v-text-field
                  color="green darken-4"
                  type="email"
                  v-model="email"
                  :rules="emailRules"
                  outlined
                  dense
                  :disabled="loading"
                ></v-text-field>
                <small>Password</small>
                <v-text-field
                  color="green darken-4"
                  :append-icon="showPassword ? `mdi-eye` : `mdi-eye-off`"
                  :disabled="loading"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="passwordRules"
                  outlined
                  dense
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-row justify="start" align="center">
                  <v-col cols="6">
                    <v-checkbox
                      id="remember-me"
                      color="green darken-4"
                      dense
                      v-model="rememberMe"
                    >
                      <template v-slot:label>
                        <small class="mt-2">Remember me</small>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <small>
                      <router-link :to="{ name: 'password-reset' }"
                        >Forgot password?</router-link
                      >
                    </small>
                  </v-col>
                </v-row>
                <!-- show login button if not loading -->
                <v-btn
                  v-if="!loading"
                  small
                  type="submit"
                  class="green darken-4 white--text font-weight-bold w-100 mb-3 text-center pt-5 pb-5"
                >
                  <span class="w-75">Login</span>
                  <v-icon small>mdi-login</v-icon>
                </v-btn>
                <!-- show progress bar loader if loading -->
                <div v-else class="text-center">
                  <v-progress-circular
                    :width="4"
                    :size="50"
                    color="green darken-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-form>
              <!-- registration route -->
              <small class="mx-auto mt-4">
                Don't have an account?
                <router-link :to="{ name: 'registrations' }"
                  >Register here</router-link
                >
              </small>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Roles from "@/utils/auth/roles";

export default {
  name: "login",
  data() {
    return {
      title: "Open Health Insurance System and Analytics (OpenHISA)",
      body: `
			This is a unique program that seeks to address the challenge of low
			coverage of high
			impact services, utilisation of health care services and the resultant
			poor outcomes.
			OpenHISA will guarantee all Nigerians a Basic Minimum Package of Health
			Services.
			`,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      rememberMe: false,
      showError: false,
      showPassword: false,
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters({ user: "user", loading: "isLoading" }),
  },
  methods: {
    ...mapActions({
      authenticateUser: "authenticateUser",
      fetchActiveEnrollees: "enrollmentModule/fetchActiveEnrollees",
      fetchBatches: "claimModule/fetchBatches"
    }),
    ...mapMutations(["keepSession"]),
    async handleLogin() {
      // validate form to show errors or warnings
      if (this.$refs.form.validate()) {
        try {
          const { email, password, rememberMe, keepSession } = this;
          await this.authenticateUser({ email, password });
          await this.fetchBatches();
          // maintain session on user login if `checkbox` is ticked
          if (rememberMe) {
            keepSession(true);
          }else{
            // use session storage alternative if `remember_me` isn't set
            sessionStorage.setItem("session", true);
          }
          // Staff roles
          const {
            DATA_ENTRY_CLERK,
            DIRECTOR_ONE,
            DIRECTOR_TWO,
            OFFICER_IN_CHARGE,
            RETENTION_STAFF,
          } = Roles;
          // should throw null error if authentication failed
          switch (this.user.user_group) {
            // redirect data-clerk staff to manage batches page
            case DATA_ENTRY_CLERK: {
              this.fetchActiveEnrollees();
              this.$router.push({ name: "manage-batches" });
              break;
            }
            case DIRECTOR_ONE: {
              this.$router.push({ name: "list-approve-batches" });
              break;
            }
            case DIRECTOR_TWO: {
              this.$router.push({ name: "list-schedule-batches" });
              break;
            }
            case OFFICER_IN_CHARGE: {
              this.$router.push({ name: "list-vet-batches" });
              break;
            }
            case RETENTION_STAFF: {
              this.$router.push({ name: "retention" });
              break;
            }
            default: {
              this.$router.push({ name: "dashboard" });
              break;
            }
          }
          // clears form input
          this.$refs.form.reset();
        } catch (error) {
          this.showError = true;
        }
      }
    },
  },
};
</script>

<style scoped>
#hero {
  min-height: 100%;
  min-width: 1024px;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  background: url("~@/assets/images/jpg/Nigerian Family.jpg") no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: left;
}
</style>
