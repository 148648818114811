/**
 * This module contain the role(account) groups for staff users on OpenHISA.
 * These roles are used by route guards and Login.vue component to check the staff user_group
 */

 const DATA_ENTRY_CLERK = "data_entry_clerk";
 const DIRECTOR_ONE = "director_1";
 const DIRECTOR_TWO = "director_2";
 const OFFICER_IN_CHARGE = "officer_in_charge";
 const RETENTION_STAFF = "retention_staff";

 export default {
     DATA_ENTRY_CLERK,
     DIRECTOR_ONE,
     DIRECTOR_TWO,
     OFFICER_IN_CHARGE,
     RETENTION_STAFF
 }